import React from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"

const StyledWrapper = styled.div`
  width: 100%;
  height: 14.2932rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledContainer = styled.div`
  position: relative;
  width: ${props => props.color ? `1rem` : `1.5rem`};
  height: ${props => props.color ? `1rem` : `1.5rem`};
  span {
    display: block;
    width: ${props => props.color ? `1rem` : `1.5rem`};
    height: ${props => props.color ? `1rem` : `1.5rem`};
    border: ${props => props.color ? `2px solid ${props.color}` : `3px solid ${props.theme.colors.primary}`};
    border-top:  ${props => props.color ? `2px solid #fff` : `3px solid #fff`};
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
`


const Loader = props => {
  return (
    <StyledWrapper>
      <StyledContainer color={props.color}>
        <motion.span
          animate={{ rotate: 360 }}
          transition={{ 
            loop: Infinity, 
            duration: 0.7,
            ease: "linear"
          }}
        >
        </motion.span>
      </StyledContainer>
    </StyledWrapper>
  )
}

export default Loader