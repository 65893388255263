import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate, Link } from "gatsby"
import { graphql } from "gatsby"
import styled from "styled-components"
import { connect } from "react-redux"
import { getProfile } from "store/actions/profile"
import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import { seoTitleSuffix, language} from "config"
import { getSearchParams } from "gatsby-query-params"
import Loader from "components/loader"
import errorImage from "content/images/error.svg"
import { isSSR } from "utils/"


const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 6.5rem);
  width: 400px;
  max-width: 90%;
  margin: auto;
  font-size: 1.5rem;
  line-height: 2.5rem;
`
const StyledButton = styled(Link)`
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  background: rgba(21, 37, 52, 0.56);
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  svg {
    width: 17px;
    height: 17px;
    margin-right: 1rem;
  }
  margin: 0 auto;
  width: 100%;
`

const StyledButtonPrimary = styled(StyledButton)`
  margin-bottom: 0;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  display: flex;
  justify-content: center;
  margin: 0;
  margin: 0 auto;
  width: 100%;
  margin-top: 17px;
  &:hover {
    filter: unset;
    transform: unset;
    box-shadow: unset;
    opacity: 0.8;
  }
`
const StyledDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 16px 0;
  span {
    width: 85px;
    height: 0;
    border: 1px solid #C6D7EA;
  }
  div {
    margin: 0 10px
  }
`

const RedirectionPage = React.memo(
  ({
    data,
    profile,
    getProfile
  }) => {
    const { frontmatter } = data.index.edges[0].node
    const { seoTitle, useSeoTitleSuffix, useSplashScreen } = frontmatter
    const queryParams = getSearchParams()
    const urlSearchParams =  new URLSearchParams(!isSSR && window.location.search).get('params') || new URLSearchParams(!isSSR && window.location.search).get('token')

    useEffect(() => {
      if(queryParams.profile_key) {
        getProfile({
          sourceKey: queryParams.source_key,
          profileKey: queryParams.profile_key,
          redirect: true,
          token: urlSearchParams,
          action: 'redirect'
        })
      }
    }, [queryParams])

    const globalState = {
      // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
      isIntroDone: useSplashScreen ? false : true,
      // darkMode is initially disabled, a hook inside the Layout component
      // will check the user's preferences and switch to dark mode if needed
      darkMode: true,
    }
    const fetchProfile = ()  => {
      getProfile({
        sourceKey: queryParams.source_key,
        profileKey: queryParams.profile_key,
        redirect: true,
        token: urlSearchParams,
        action: 'redirect'
      })
    }


    return (
      <GlobalStateProvider initialState={globalState}>
        <Layout>
          <SEO
            title={
              useSeoTitleSuffix
                ? `${seoTitle} - ${seoTitleSuffix}`
                : `${seoTitle}`
            }
          />
         
            <StyledDiv>
              {profile.f ? (
                <>
                  <img src={errorImage} alt="error" />
                  Une erreur s'est produit
                  <StyledButtonPrimary to="#" onClick={fetchProfile}>Réessayer</StyledButtonPrimary>
                  <StyledDivider>
                    <span></span>
                    <div>ou</div>
                    <span></span>
                  </StyledDivider>
                  <StyledButton to={`/jobs`}>
                    {language.redirection_button}
                  </StyledButton>
                </>
              ):(
                <Loader />
              )}
            </StyledDiv>
        </Layout>
      </GlobalStateProvider>
    )
  }
)

RedirectionPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  file: state.profile.file,
})

export default connect(mapStateToProps, { getProfile })(RedirectionPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/index/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    drop: allMdx(filter: { fileAbsolutePath: { regex: "/index/drop/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
            subtitle
            subtitlePrefix
          }
        }
      }
    }
  }
`
